import Grid from "@material-ui/core/Grid"
import ListItem from "@material-ui/core/ListItem"
import { navigate } from "gatsby"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import LocationOn from "@material-ui/icons/LocationOn"
import Hidden from "@material-ui/core/Hidden"
import ListItemText from "@material-ui/core/ListItemText"
import Link from "@material-ui/core/Link"
import DraftsIcon from "@material-ui/icons/Drafts"
import PhoneIcon from "@material-ui/icons/Phone"
// import ChatIcon from "@material-ui/icons/Chat"
import React, { useEffect, useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
// import Modal from "@material-ui/core/Modal"
// import io from "socket.io-client"
// import Box from "@material-ui/core/Box"
// import TextField from "@material-ui/core/TextField"
// import Typography from "@material-ui/core/Typography"
// import SendIcon from "@material-ui/icons/send"
// import IconButton from "@material-ui/core/IconButton"
// import { useDispatch, useSelector } from "react-redux"
// import { increase } from "../store/action"
// import Badge from "@material-ui/core/Badge"
// import uuid from "uuid"
// let marketId, marketName, marketMessages;
// if (typeof window !== "undefined") {
//   marketId = localStorage.getItem("marketId");
//   marketName = localStorage.getItem("marketName");
//   marketMessages = JSON.parse(localStorage.getItem("marketMessages"));
//   if (!marketId) {
//     marketId = uuid()
//     marketName = "Имя"
//     marketMessages = []
//     localStorage.setItem("marketId", marketId);
//     localStorage.setItem("marketName", marketName );
//     localStorage.setItem("marketMessages", marketMessages );
//   }
// }
// const socket = io.connect("https://strmarket-294310.ey.r.appspot.com/", {
//   query: {
//     marketId,
//     marketName
//   },
// })
// socket.on("connect", function () {
//   console.log("connected to server")
// })

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `${50}%`,
    left: `${50}%`,
    marginRight: `-50%`,
    transform: `translate(-50%, -50%)`,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
  }
}))

export default () => {
  // const dispatch = useDispatch()
  const classes = useStyles()
  // const [open, setOpen] = React.useState(false)
  // const [inputValue, setInputValue] = React.useState("")
  // const [messages, setMessages] = React.useState(marketMessages);
  // const [modalWidth, setModalWidth] = useState(0)
  // const [modalHeight, setModalHeight] = useState(0)
  // socket.on("message", function (msg) {
  //   const newMessages = JSON.parse(JSON.stringify(messages));
  //   newMessages.push({ server: true, message: msg })
  //   setMessages(newMessages);
  //   localStorage.setItem("marketMessages", JSON.stringify(newMessages));
  // })
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setModalHeight(window.innerHeight*0.9)
  //     setModalWidth(window.innerWidth*0.9 > 600 ? 600: window.innerWidth*0.9)
  //   }
  // }, [])
  // const resizeHandler = () => {
  //   if (typeof window !== "undefined") {
  //     setModalWidth(window.innerWidth*0.9 > 600 ? 600: window.innerWidth*0.9)
  //     setModalHeight(window.innerHeight*0.9)
  //   }
  // }
  //
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("resize", resizeHandler)
  //   }
  //   return () => {
  //     if (typeof window !== "undefined") {
  //       window.removeEventListener("resize", resizeHandler)
  //     }
  //   }
  // })
  // const handleOpen = () => {
  //   setOpen(true)
  // }
  // const handleClose = () => {
  //   setOpen(false)
  // }
  // const body = (
  //   <Box
  //     className={classes.paper}
  //     style={{
  //       width: modalWidth,
  //       height: modalHeight,
  //     }}
  //   >
  //     <Box style={{ flexGrow: 1 }}>
  //       {messages.map((el, id) => (
  //         <Typography variant="body1" component="p" key={id}>
  //           {el.message}
  //         </Typography>
  //       ))}
  //     </Box>
  //     <Box>
  //       <form
  //         onSubmit={event => {
  //           event.preventDefault()
  //           if (inputValue!=='') {
  //             const newMessages = JSON.parse(JSON.stringify(messages));
  //             newMessages.push({server: false, message: inputValue})
  //             localStorage.setItem("marketMessages", JSON.stringify(newMessages));
  //             socket.send(inputValue)
  //             setMessages(newMessages)
  //             setInputValue("")
  //           }
  //         }}
  //       >
  //         <Box display={"flex"}>
  //           <TextField
  //             id="input-with-icon-grid"
  //             label="Напишите сообщение"
  //             fullWidth
  //             value={inputValue}
  //             autoComplete={'off'}
  //             onChange={event => setInputValue(event.target.value)}
  //           />
  //           <IconButton aria-label="send" color="primary" type={"submit"}>
  //             <SendIcon />
  //           </IconButton>
  //         </Box>
  //       </form>
  //     </Box>
  //   </Box>
  // )
  return (
    <Grid container justify={"center"}>
      <Grid item>
        <ListItem button onClick={() => navigate("map")} color="#000">
          <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
            <LocationOn />
          </ListItemIcon>
          <Hidden smDown>
            <ListItemText
              primary="г. Жабинка, ул. Пионерская улица, 4"
              className={classes.text}
            />
          </Hidden>
        </ListItem>
      </Grid>
      <Grid item>
        <Link underline="none" href="mailto:strmarket494@gmail.com">
          <ListItem button>
            <ListItemIcon  style={{ display: "flex", justifyContent: "center" }}>
              <DraftsIcon />
            </ListItemIcon>
            <Hidden smDown>
              <ListItemText
                primary="strmarket494@gmail.com"
                className={classes.text}
              />
            </Hidden>
          </ListItem>
        </Link>
      </Grid>
      <Grid item>
        <Link underline="none" href="tel:+375298226706">
          <ListItem button>
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              <PhoneIcon />
            </ListItemIcon>
            <Hidden smDown>
              <ListItemText
                className={classes.text}
                primary={"+375 (29) 822-67-06"}
              />
            </Hidden>
          </ListItem>
        </Link>
      </Grid>
      {/*<Grid item>*/}
      {/*  <ListItem*/}
      {/*    button*/}
      {/*    alignItems={"center"}*/}
      {/*    onClick={() => {*/}
      {/*      dispatch(increase())*/}
      {/*      handleOpen()*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <ListItemIcon  >*/}
      {/*      <Badge*/}
      {/*        badgeContent={useSelector(state => state.main.count)}*/}
      {/*        color="secondary"*/}
      {/*        className={"icon"}*/}
      {/*      >*/}
      {/*        <ChatIcon />*/}
      {/*      </Badge>*/}
      {/*    </ListItemIcon >*/}
      {/*    <Hidden smDown>*/}
      {/*      <ListItemText primary={`Чат`} className={classes.text} />*/}
      {/*    </Hidden>*/}
      {/*  </ListItem>*/}
      {/*</Grid>*/}
      {/*<Modal*/}
      {/*  open={open}*/}
      {/*  onClose={() => {*/}
      {/*    handleClose()*/}
      {/*  }}*/}
      {/*  aria-labelledby="simple-modal-title"*/}
      {/*  aria-describedby="simple-modal-description"*/}
      {/*>*/}
      {/*  {body}*/}
      {/*</Modal>*/}
    </Grid>
  )
}
