import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery} from "gatsby"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import CategoryList from "../components/CategoryList";
import SocialList from "../components/SocialList";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
  siteInfo: {
    minHeight: "calc(100vh - 48px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  siteSocial: {
    flexGrow: 0,
  },
  categoriesPhoto: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.text.primary,
  },
}))

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        nodes {
          contentful_id
          price
          newPrice
          spaceId
          name
          description {
            description
          }
          photo {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
      contentfulImage(title: { eq: "backgroundFirstSection" }) {
        image {
          fluid {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
      contentfulRichText(title: { eq: "О магазине (текст)" }) {
        text {
          json
        }
      }
    }
  `)
  const products = data.allContentfulProduct.nodes
  const image = data.contentfulImage.image.fluid

  const classes = useStyles()
  return (
    <Layout>
      <SEO />
      <Typography variant="srOnly" component="h1">
        Строймаркет
      </Typography>
      <Box component="section" className={classes.siteInfo}>

          <SocialList/>
          <Grid container spacing={3} className={classes.categoriesPhoto} >

            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="h5"
                  component="h2"
                  align="justify"
                  color="textPrimary"
                >
                  Строймаркет - магазин, в котором вы найдёте строительные материалы и инструменты, а также товары для дома, сада и огорода. В нашем магазине вы найдёте более 30,000 товаров.
                </Typography>
                <Typography
                  variant="h5"
                  component="p"
                  align="justify"
                  color="textPrimary"
                >
                  Работаем с 8.00 до 19.00 без выходных.
                </Typography>
                <Typography
                  variant="h5"
                  component="p"
                  align="justify"
                  color="textPrimary"
                >
                  <Link underline="none" href="tel:+375298226706">
                      Телефон: +375 (29) 822-67-06
                  </Link>
                </Typography>
              </Box>
              <Grid item xs={12} md={12} container alignItems="top" justify={"center"}>
                <Grid xs={12} >
                  <Img fluid={image} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} container alignItems="top" justify={"center"}>
            <CategoryList/>
            </Grid>

          </Grid>
      </Box>
      {/*<section>*/}
      {/*  <Typography variant="h4" component="h2" color="textPrimary">*/}
      {/*    Хиты продаж*/}
      {/*  </Typography>*/}
      {/*  <PopularList products={products} />*/}
      {/*</section>*/}
    </Layout>
  )
}

export default IndexPage
