import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import Typography from "@material-ui/core/Typography"
import { Box } from "@material-ui/core"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProductsCategory {
        nodes {
          title
          icon {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  `)
  const categories = data.allContentfulProductsCategory.nodes;
  const Category = ({ category }) => (
      <Grid
        item
        xs={6}
        sm={4}
        container
        justify={"center"}
        alignItems={"center"}
        direction={"column"}
      >
        <Img
          fluid={category.icon.fluid}
          style={{ width: "100px", height: "100px" }}
        />
        <Typography variant="h6" component="p" color="textPrimary" align={'center'} >
          {category.title}
        </Typography>
      </Grid>
  )
  return (
    <Box my={3}>
    <Grid container spacing={3}>
      {categories.map(category => (
        <Category category={category} key={category.title} />
      ))}
    </Grid>
    </Box>
  )
}
